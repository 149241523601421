import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CountryCodes, EventReservationService, IEventReservationTypeResponse, StateService } from '@i-con/shared/common';
import { CountryISO, NgxIntlTelInputModule, SearchCountryField } from 'ngx-intl-tel-input';
import { Observable } from 'rxjs';

interface IEventReservationState {
  success: boolean;
  return_msg: string;
}

const initialState: IEventReservationState = {
  success: false,
  return_msg: '',
};

@Component({
  selector: 'app-dialog-form',
  standalone: true,
  imports: [
    CommonModule,
    NgxIntlTelInputModule,
    MatSelectModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDialogModule,
  ],
  templateUrl: './event-dialog-form.component.html',
  styleUrls: ['./event-dialog-form.component.scss'],
})
export class EventDialogFormComponent extends StateService<IEventReservationState> implements OnInit {
  header_title: string;
  event_class: string;

  selected_countries = CountryISO;

  country_codes = CountryCodes;

  constructor(@Inject(MAT_DIALOG_DATA) data: any, private formBuilder: UntypedFormBuilder, private eventReservationService: EventReservationService) {
    super(initialState);
    this.header_title = data.header_title;
    this.event_class = data.event_class;
  }

  SearchCountryField = SearchCountryField;

  reserveTableForm!: UntypedFormGroup;
  submitted = false;

  postData$!: Observable<IEventReservationTypeResponse>;
  responseState$: Observable<IEventReservationState> = this.getState(state => state);

  ngOnInit(): void {
    this.postData$ = this.eventReservationService.postData$;
    this.responseState$ = this.getState(state => state);
    this.reserveTableForm = this.formBuilder.group({
      nameControl: ['', [Validators.required, Validators.minLength(2)]],
      surnameControl: ['', [Validators.required, Validators.minLength(2)]],
      emailControl: ['', [Validators.required, Validators.email]],
      companyControl: ['', [Validators.required]],
      phoneControl: ['', [Validators.required]],
      typeControl: ['', [Validators.required]],
    });
  }

  get f() {
    return this.reserveTableForm.controls;
  }

  isOpening(event_class: string): boolean {
    if (event_class == 'opening') {
      return true;
    } else {
      return false;
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.reserveTableForm.invalid) {
      return;
    }

    const reserveration_object = {
      title: this.header_title,
      name: this.reserveTableForm.value.nameControl,
      surname: this.reserveTableForm.value.surnameControl,
      companyName: this.reserveTableForm.value.companyControl,
      dialCode: this.reserveTableForm.value.phoneControl.dialCode,
      country: this.reserveTableForm.value.phoneControl.countryCode,
      phoneNumber: this.reserveTableForm.value.phoneControl.number,
      reservationType: this.reserveTableForm.value.typeControl,
      email: this.reserveTableForm.value.emailControl,
    };

    this.eventReservationService.eventReservation(reserveration_object);

    this.postData$.subscribe(res => {
      if (res.response?.statusCode == 201) {
        this.setState({
          success: true,
          return_msg: '',
        });
      } else {
        this.setState({
          success: false,
          return_msg: res.response?.message,
        });
      }
    });
  }
}
