<div class="reservation-container">
	<div class="container">
		<div class="header-container">
			<h3>Fill out your information</h3>
			<p>Fill in your information below and our team will get back to you to arrange your booking.</p>
		</div>
		<div class="form-container-wrapper">
			<primary-contact-form (setPrimaryContact)="createPrimaryContact($event)" [buttonText]="'Confirm'"
				[previousStep]="previousStep" (goToStep)="goBack($event)"
				[formTitle]="'Primary Contact Details'"></primary-contact-form>
		</div>
	</div>
</div>