import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AuthorizationService, BookingFormGroupService, IBookingFormReservation, IFormField, IProfileType, ProfileService } from '@i-con/shared/common';
import { Observable, Subject, debounceTime, fromEvent } from 'rxjs';
import { TermsFormComponent } from '../terms-form/terms-form.component';
import { BookingFormComponent } from '../booking-form/booking-form.component';
import { BillingComponent } from '../billing-form/billing-form.component';

@Component({
    selector: 'i-con-booking-form-group',
    standalone: true,
    imports: [
        CommonModule, FormsModule, 
        MatFormFieldModule, ReactiveFormsModule, 
        BookingFormComponent, BillingComponent, TermsFormComponent
    ],
    templateUrl: './booking-form-group.component.html',
    styleUrl: './booking-form-group.component.scss',
})
export class BookingFormGroupComponent implements OnInit {

    @Output() goToStepEmitter = new EventEmitter();
	@Output() bookingFormEmmitter = new EventEmitter();
	@Output() goBackPanel = new EventEmitter();
	@Output() profileEmitter = new EventEmitter();
	@Output() loggedInEmitter  = new EventEmitter();

	@Input() previousStep!: number;
	@Input() btnText: string = "";
	@Input() isPanel: boolean = false;
	@Input() confirmationName: string = "";

	@Input() errorMessages: string[] = [];

    fieldsDataPart1: IFormField[] = [
		{
			controlName: 'firstName',
			fieldType: 'text',
			class: 'input-field',
			value: '',
			placeHolder: 'First Name',
			fullLine: false,
			validations: {
				required: true,
				maxLength: 25,
				minLength: 3
			}
		},
		{
			controlName: 'lastName',
			fieldType: 'text',
			class: 'input-field',
			placeHolder: 'Last Name',
			fullLine: false,
			value: '',
			validations: {
				required: true,
				maxLength: 25,
				minLength: 3,
			}
		},
		{
			controlName: 'email',
			fieldType: 'text',
			class: 'input-field',
			placeHolder: 'Email Address',
			fullLine: true,
			value: '',
			validations: {
				required: true,
				email: true
			}
		}
	]

	fieldsDataPart2: IFormField[] = [ 
		{
			controlName: 'address',
			fieldType: 'text',
			class: 'input-field',
			value: '',
			placeHolder: 'Address',
			fullLine: false,
			validations: {
				required: true,
				maxLength: 255,
				minLength: 5
			}
		},
		{
			controlName: 'country',
			fieldType: 'text',
			class: 'input-field',
			value: '',
			placeHolder: 'Country',
			fullLine: false,
			validations: {
				required: true,
				maxLength: 255,
				minLength: 2
			}
		}
		,
		{
			controlName: 'city',
			fieldType: 'text',
			class: 'input-field',
			value: '',
			placeHolder: 'City',
			fullLine: false,
			validations: {
				required: true,
				maxLength: 255,
				minLength: 5
			}
		}
		,
		{
			controlName: 'zip',
			fieldType: 'text',
			class: 'input-field',
			value: '',
			placeHolder: 'Zip/Postal Code',
			fullLine: false,
			validations: {
				required: true,
				maxLength: 10,
				minLength: 2
			}
		},
		{
			controlName: 'company',
			fieldType: 'text',
			class: 'input-field',
			value: '',
			placeHolder: 'Company Name',
			fullLine: false,
			validations: {
				required: true,
				maxLength: 255,
				minLength: 3
			}
		},
		{
			controlName: 'vat',
			fieldType: 'text',
			class: 'input-field',
			value: '',
			placeHolder: 'VAT Number (if applicable)',
			fullLine: false,
			validations: {
				maxLength: 255,
				minLength: 3
			}
		}
	]

	fieldsDataPart3: IFormField[] = [
		{
			controlName: 'terms',
			fieldType: 'checkbox',
			class: 'input-field terms',
			value: true,
			placeHolder: 'Your agreement',
			fullLine: true,
			validations: {
				requiredTrue: true
			}
		},
		{
			controlName: 'consent',
			fieldType: 'checkbox',
			class: 'input-field',
			value: true,
			placeHolder: 'Optin Consent',
			fullLine: true,
			validations: {
			}
		}
	]

    submitted$!: Observable<boolean>;
	submitted!: boolean;

  	bookingFormGroup!: FormGroup;

	profileData$!: Observable<IProfileType>;
	isLoggedIn: boolean = false;

	private formSubmit$: Subject<void> = new Subject<void>();


	constructor(
		private bookingFormGroupService: BookingFormGroupService,
		private profileService: ProfileService,
		private authService: AuthorizationService
	) {

	}

    ngOnInit() {		

		this.isLoggedIn = this.authService.isLoggedIn();

		this.bookingFormGroup = new FormGroup({});
        this.submitted$ = this.bookingFormGroupService.submitted$;
		this.profileData$ = this.profileService.profileData$;

		if(this.isPanel) {
			this.profileService.getProfileData();
		}

		this.submitted$.subscribe((res) => {
			this.submitted = res;
		});		

		// setup 1 second debouncetime to prevent user from making multiple submissions
		this.formSubmit$.pipe(
			debounceTime(500)
		).subscribe(() => {
			this.submitForm();
		});

    }

	submitDebounceForm() {
		if (this.isPanel) {
			this.formSubmit$.next();
		}
		else {
			this.submitForm();
		}
	}

    submitForm() {
		this.bookingFormGroupService.setSubmitted(true);

		if (this.bookingFormGroup.valid) {
			if (this.isPanel) {
				const profileData: IProfileType = {
					contactDetails: {
						name: this.bookingFormGroup.value.part1booking.part1[0],
						surname: this.bookingFormGroup.value.part1booking.part1[1],
						email: this.bookingFormGroup.value.part1booking.part1[2],
						termsAgreement: true,
						optinConsent: true
					},
					billingDetails: {
						city: this.bookingFormGroup.value.part2billing.part2[2],
						address: this.bookingFormGroup.value.part2billing.part2[0],
						country: this.bookingFormGroup.value.part2billing.part2[1],
						postalCode: this.bookingFormGroup.value.part2billing.part2[3],
						companyName: this.bookingFormGroup.value.part2billing.part2[4],
						vatNumber: this.bookingFormGroup.value.part2billing.part2[5]
					}
				}
				// profileData.contactDetails.email = profileData.contactDetails.email.toLowerCase();
				this.profileEmitter.emit(profileData);
			}
			else {
				if (this.isLoggedIn) {
					this.loggedInEmitter.emit();
				}
				else {
					const bookingFormInfo: IBookingFormReservation = {
						name: this.bookingFormGroup.value.part1booking.part1[0],
						surname: this.bookingFormGroup.value.part1booking.part1[1],
						email: this.bookingFormGroup.value.part1booking.part1[2],
						address: this.bookingFormGroup.value.part2billing.part2[0],
						country: this.bookingFormGroup.value.part2billing.part2[1],
						city: this.bookingFormGroup.value.part2billing.part2[2],
						postalCode: this.bookingFormGroup.value.part2billing.part2[3],
						companyName: this.bookingFormGroup.value.part2billing.part2[4],
						vatNumber: this.bookingFormGroup.value.part2billing.part2[5],
						termsAgreement: this.bookingFormGroup.value.part3terms.part3[0],
						optinConsent: this.bookingFormGroup.value.part3terms.part3[1],
					};

					bookingFormInfo.email = bookingFormInfo.email.toLowerCase();
					this.bookingFormEmmitter.emit(bookingFormInfo);
				}
			}
		}
		else {
			return;
		}
	}

	// event emitter to go back to the listing of the sponsorships
	goBack() {

		if (this.isPanel) {
			this.goBackPanel.emit();
		}
		else {
			this.goToStepEmitter.emit({step: this.previousStep});
		}

	}

}
