import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PrimaryContactFormComponent } from '@i-con/shared/ui';


@Component({
  selector: 'app-sponsorship-create-primary-contact',
  standalone: true,
  templateUrl: './sponsorship-create-primary-contact.html',
  styleUrls: ['./sponsorship-create-primary-contact.scss'],
  imports: [CommonModule, PrimaryContactFormComponent],
})
export class SponsorshipCreatePrimaryContactComponent {
  @Output('setPrimaryContact') setPrimaryContact: EventEmitter<any> = new EventEmitter();
  @Output('goToStep') goToStep: EventEmitter<any> = new EventEmitter();
  @Input() previousStep!: number;

  constructor() {}

  createPrimaryContact($event: any) {
    const { primaryContact } = $event;
    this.setPrimaryContact.emit({ primaryContact });
  }

  goBack($event: any) {
    const { step } = $event;
    this.goToStep.emit({ step: step });
  }
}
